<template>
  <div :class="'tabbar-group ' + (publishVisible?'publish-visible':'')">
    <div class="publish-shade" @click="publishVisible=false"></div>
    <div class="publish-panel">
      <!--<div class="publish-panel-line">-->
      <!--<a href="weixin://dl/business/?t=9kPtZX8JGkm" class="publish-panel-item">-->
      <!--<div class="word-icon primary">-->
      <!--<van-icon name="plus"/>-->
      <!--</div>-->
      <!--上传方案-->
      <!--</a>-->
      <!--</div>-->
      <div class="publish-panel-line">
        <a class="publish-panel-item" href="https://www.001ppt.com/urlScheme?remark=发布需求&path=pages/resource/publish/index/index">
          <div class="word-icon">需</div>
          发布需求
        </a>
        <a class="publish-panel-item" href="https://www.001ppt.com/urlScheme?remark=发布资源&path=pages/resource/publish/index/index&query=currTab=resource">
          <div class="word-icon">供</div>
          发布资源
        </a>
      </div>
    </div>

    <div class="tabbar-route">
      <transition :name="transitionName">
        <router-view class="child-view"></router-view>
      </transition>
    </div>
    <div class="tabbar-menu" v-show="$route.path!=='/ai'">
      <router-link tag="div" to="/home" class="tabbar-item" replace :class="{'router-link-active': $route.name==='home' || $route.name==='chat' || $route.name==='hotRank'}">
        <img class="tabbar-item-icon" src="@/assets/svg/home_active.svg" alt="" v-if="$route.name==='home' || $route.name==='chat' || $route.name==='hotRank'">
        <img class="tabbar-item-icon" src="@/assets/svg/home.svg" alt="" v-else>
        <span>方案</span>
      </router-link>

      <router-link tag="div" class="tabbar-item" to="/planList" replace>
        <img class="tabbar-item-icon" src="@/assets/svg/tabbar-collection-active.svg" alt="" v-if="$route.name==='planList'">
        <img class="tabbar-item-icon" src="@/assets/svg/tabbar-collection.svg" alt="" v-else>
        <span>合集</span>
      </router-link>

      <div class="tabba-item plus-icon" @click="goHomeSearch">
        <van-icon name="search"/>
      </div>

      <router-link tag="div" to="/rank" class="tabbar-item" replace>
<!--        <img src="https://cdn.001ppt.cn/h5/assets/svg/hot.svg" class="tabbar-item-bardge" style="right: 15px;"/>-->
        <img class="tabbar-item-icon" src="@/assets/svg/eran_yanzhi_active.svg" alt="" v-if="$route.name==='rank'">
        <img class="tabbar-item-icon" src="@/assets/svg/eran_yanzhi.svg" alt="" v-else>
        <span>奖励</span>
      </router-link>

      <router-link tag="div" to="/mine" class="tabbar-item" replace>
        <img class="tabbar-item-icon" src="@/assets/svg/personal_active.svg" alt="" v-if="$route.name==='mine'">
        <img class="tabbar-item-icon" src="@/assets/svg/personal.svg" alt="" v-else>
        <span>我</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  // import {Icon} from 'vant'
  const {Icon} = vant;

  export default {
    name: "tabbar",
    components: {'van-icon': Icon},
    created() {
      let resourceDot = localStorage.getItem('resourceDot');
      if (resourceDot === false) {
        return;
      }
      this.resourceDot = true;
    },
    data() {
      return {
        transitionName: 'slide-right',
        publishVisible: false,
        resourceDot: false
      }
    },
    watch: {
      '$route'(to, from) {
        this.publishVisible = false;
        if (from.name == 'home') {
          this.transitionName = 'slide-left';
        } else if (from.name == 'task' && to.name == 'mine') {
          this.transitionName = 'slide-left';
        } else {
          this.transitionName = 'slide-right';
        }
      }
    },


    methods:{
      goResource(){
        // localStorage.setItem('resourceDot',false);
        // this.resourceDot = false;
        // window.location.href='https://www.001ppt.com/urlScheme?remark=资源页&path=pages/tabbar/resource/index';
        this.$router.push('/resource')
      },


      async goHomeSearch(){
        if(this.$route.path !== '/'){
          await this.$router.push('/')
        }
        const interval = setInterval(()=>{
          if(window.showHomeSearch){
            window.showHomeSearch();
            clearInterval(interval)
          }
        },200)
      }

    }
  }
</script>

<style scoped lang="less">
  @publish-panel-width: 254px;
  .tabbar-group {
    height: 100vh;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;

    .tabbar-route {
      flex: 1;
      height: calc(100vh - @tabbar-height - 1px);
      overflow-y: auto;
      position: relative;
      overflow-x: hidden;

      .slide-left-enter, .slide-right-leave-active {
        opacity: 0;
        -webkit-transform: translate(30px, 0);
        transform: translate(30px, 0);
      }

      .slide-left-leave-active, .slide-right-enter {
        opacity: 0;
        -webkit-transform: translate(-30px, 0);
        transform: translate(-30px, 0);
      }

      .child-view {
        transition: all .5s cubic-bezier(.55, 0, .1, 1);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

    }

    .tabbar-menu {
      position: fixed;
      /*position: -webkit-sticky;*/
      /*position: sticky;*/
      bottom: 0;
      width: 100vw;
      border-top: 1px solid #ddd;
      height: @tabbar-height;
      display: flex;
      background-color: #fff;
      z-index: 1000;
      align-items: center;

      .tabbar-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: @mini;
        position: relative;

        i {
          font-size: 25px;
          display: block;
        }

        span {
          font-size: @mini;
        }

        &:not(.router-link-active) {
          span {
            opacity: .3 !important;
          }

          i {
            opacity: .3;
          }

        }

        &.router-link-active {
          color: @theme-color;

          i {
            opacity: 1;
          }

          span {
            color: @theme-color;
          }
        }
      }
    }
  }

  .plus-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #151d36;
    color: #fff;
    height: 36px;
    width: 36px;
    margin: 0 12px;
    border-radius: 100px;
    transition: transform .3s;
    font-weight: bolder;
    font-size: 20px;
  }

  .publish-shade {
    opacity: 0;
    transition: opacity .3s;
  }

  .publish-panel {
    height: 0;
    width: 0;
    overflow: hidden;
    z-index: 1000;
    position: fixed;
    bottom: @tabbar-height + 1px;
    left: calc((100vw - @publish-panel-width) / 2);
    transform: scale(.1);
    background: #fff;
    color: #151d36;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .publish-panel-line {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
    }

    .publish-panel-item {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
    }
  }

  .publish-visible {
    .plus-icon {
      transform: rotateZ(45deg);
    }

    .publish-shade {
      top: 0;
      bottom: @tabbar-height;
      left: 0;
      right: 0;
      position: fixed;
      opacity: .9;
      background: #151d36;
      z-index: 999;
    }

    .publish-panel {
      /*height: 196px;*/
      height: 98px;
      width: @publish-panel-width;
      transform: scale(1);
      transition: transform .3s;
    }
  }

  .word-icon {
    height: 45px;
    width: 45px;
    background: rgba(228, 76, 76, 1);
    color: #fff;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    font-size: 18px;
    margin-bottom: 8px;

    &.primary {
      background: #151d36;
      height: 55px;
      width: 55px;
      font-size: 24px;
      margin-top: 6px;
    }
  }

  .tabbar-item-icon {
    height: 24px;
    width: 24px;
    position: relative;
  }

  .tabbar-item-dot {
    position: absolute;
    top: 0;
    left: -5px;
    background: #FF3B30;
    border-radius: 100px;
  }

  .tabbar-item-bardge{
    height: 10px;
    position: absolute;
    right: -15px;
    top: 0;
    z-index: 20;
  }


</style>
