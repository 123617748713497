<template>
    <div class="container page">
        <div class="page_con">
            <div class="page_con_wrap">
                <img class="white_logo" src="https://cdn.001ppt.cn/h5/assets/images/white-logo.png">
                <span class="txt">一个让策划人「快速找案+副业赚钱」的方案下载平台</span>
                <router-link to="/login/scanCode" class="btn wx_btn" v-if="getBrowser() == 'h5'">微信快速登录</router-link>
                <router-link to="/wx" class="btn wx_btn" v-else>微信快速登录</router-link>
                <a href="javascript: void (0)" @click="login" class="btn phone_btn">手机号登录</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            login(){
                this.$router.replace('/login/phoneCode');
            }
        }
    }
</script>



<style scoped lang="less">
    .page{
        background-color: #151d36;
    }
    .page_con{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
    .page_con_wrap{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .white_logo{
        height: 35px;
    }
    .page_con_wrap .txt{
        color: #fff;
        line-height: 150%;
        font-size: 10px;
        font-weight: bold;
        margin-top: 14px;
        margin-bottom: 197px;
    }
    .page_con_wrap .wx_btn{
        background-color: #fff;
        border-radius: 21px;
        display: flex;
        width: 260px;
        height: 44px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
    .page_con_wrap .phone_btn{
        margin-top: 25px;
        box-sizing: border-box;
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 21px;
        display: flex;
        width: 260px;
        height: 44px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
</style>
