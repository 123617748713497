<template>
    <div class="container page">
        <div class="page_con">
            <div class="page_con_wrap">
                <img class="white_logo" src="https://cdn.001ppt.cn/h5/assets/images/white-logo.png">
                <span class="txt">一个让策划人「快速找案+副业赚钱」的方案下载平台</span>

                <span class="tip">为保护帐号安全，首次登录需绑定手机号</span>

                <div class="input_line">
                    <div class="tel_input">
                        <div class="jia86">
                            <div class="select-box">
                                <div class="select-current" @click="openClose">
                                    <span class="current-name">{{option[index].name}}</span>
                                </div>
                                <div class="option-list" v-if="isShow">
                                    <span class="option"
                                          v-for="(item,index) in option"
                                          :key="index"
                                          @click="optionTap(index)">{{item.name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="tel">
                            <input type="text" placeholder="请输入手机号" style="border: none; outline: none;background: transparent" v-model="mobile">
                        </div>
                    </div>
                    <div class="yanz_code">
                        <input type="number" class="input_code" placeholder="请输入验证码" v-model="validateCode">
                        <div class="tap_get_code">
                            <span @click="sendCode" v-if="!isSend">获取验证码</span>
                            <span v-else>{{timeDown}}s后重试</span>
                        </div>
                    </div>
                </div>

                <div class="btn" @click="goLogin">确认绑定</div>
            </div>
        </div>


    </div>
</template>

<script>
    import {user} from '@/api'
    import {mapMutations} from 'vuex'

    const TIME = 60;
    export default {
        name: "phoneCode",
        data(){
            return {
                option: [
                    {id: 1, name: '+86'}
                ],
                isShow: false,
                isSend: false,
                index: 0,
                mobile: '',
                validateCode: '',
                timeDown: TIME,
            }
        },
        methods: {
            ...mapMutations(['SET_TOKEN','SET_USERINFO','SET_MEMBER']),
            optionTap(index){
                this.index = index;
                this.isShow = false;
            },
            openClose() {
                this.isShow = !this.isShow;
            },

            //发送验证码
            async sendCode(){
                var reg = /^1(3|4|5|6|7|8|9)d{9}$/;
                if(!this.mobile) return this.$toast.info('手机号错误');
                this.$loading();
                try {
                    const {code, data} = await user.getPhoneCode(this.mobile);
                    if(code != 1) return this.$toast.error('获取失败');
                    this.isSend = true;

                    var timer = setInterval(_ => {
                        if(this.timeDown > 1){
                            this.timeDown --;
                        }else {
                            this.isSend = false;
                            clearInterval(timer);
                            this.timeDown = TIME;
                        }
                    },1000);

                }catch (e) {

                }finally {
                    this.$loading.close();
                }

            },

            async goLogin(){
                if(!this.mobile) return this.$toast.info('请输入手机号');
                if(!this.validateCode) return this.$toast.info('请输入验证码');
                try {
                    this.$loading();
                    const res = await user.bindMobile({mobile: this.mobile,validateCode: this.validateCode});
                    if(res.code != 1) return this.$toast.error(res.msg);
                    const userRes = await user.info();
                    this.SET_USERINFO(userRes.data);
                    this.$router.replace('/home');
                }catch (e) {
                    this.$toast('请求失败');
                }finally {
                    this.$loading.close();
                }

            }



        }
    }
</script>

<style scoped lang="less">
    .page{
        background-color: #151d36;
        position: relative;
    }



    .page_con{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }
    .page_con_wrap{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .white_logo{
        height: 35px;
    }
    .page_con_wrap .txt{
        color: #fff;
        line-height: 150%;
        font-size: 12px;
        font-weight: bold;
        margin-top: 14px;
        margin-bottom: 30px;
    }

    .tip{
        color: #999;
        font-size: 12px;
        margin-bottom: 30px;
    }


    .page_con_wrap .btn{
        background-color: #fff;
        border-radius: 21px;
        display: flex;
        width: 260px;
        padding: 11px 0;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 75px;
    }

    .input_line{
        display: flex;
        flex-direction: column;
        margin-bottom: 59px;
    }

    .page_con_wrap .tel_input{
        display: flex;
        margin-bottom: 15px;
    }
    .page_con_wrap .tel_input .jia86{
        background-color: #f3f3f5;
        padding: 7px 0;
        border-radius: 4px;
    }
    .phcolor {
        color: #D3D3D3;
    }
    .page_con_wrap .tel_input .tel{
        flex: 1;
        margin-left: 10px;
        background-color: #f3f3f5;
        border-radius: 4px;
        font-size: 14px;
        padding: 7px;
    }
    .page_con_wrap .yanz_code{
        background-color: #f3f3f5;
        border-radius: 4px;
        padding: 7px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .page_con_wrap .yanz_code .input_code{
        flex: 1;
        font-size: 14px;
        border: none;
        outline: none;
        background-color: #f3f3f5;
    }
    .page_con_wrap .yanz_code .tap_get_code{
        font-size: 12px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #999999;
    }

    /* 下拉 */
    .select-box {
        position: relative;
        width: 100%;
    }

    .select-current {
        width: 65px;
        position: relative;
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;
    }

    .select-current::after {
        position: absolute;
        display: block;
        right: 8px;
        top: 8px;
        content: '';
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top: 5px solid #D3D3D3;
    }

    .current-name {
        display: block;
        width: 85%;
        height: 100%;
        word-wrap: normal;
        overflow: hidden;
        color: #D3D3D3;
    }

    .option-list {
        position: absolute;
        left: 0;
        top: 27px;
        width: 100%;
        padding: 2px 5px;
        border-radius: 3px;
        box-sizing: border-box;
        z-index: 99;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) inset;
        background-color: #fff;
    }

    .option {
        font-size: 12px;
        display: block;
        width: 100%;
        line-height: 35px;
        border-bottom: 1px solid #eee;
    }

    .option:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
</style>
